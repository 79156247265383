<template>
    <div class="dashboard-container" v-loading="loading">
        <div style="margin-bottom: 20px;margin-top: 20px;margin-right: 20px;">
            <el-row>

                <el-col :span="24" align="right">
                    <el-button  type="primary" size="mini"   @click="addgrowRecord">添加</el-button>
                </el-col>

            </el-row>
        </div>
        <!-- 添加或编辑类别 -->
        <el-dialog :title="title" :visible="showDialog" @close="btnCancel">
            <!-- 表单 -->
            <el-form ref="perForm" :model="bmsGrowRecordDto" :rules="rules" label-width="120px">

                <el-form-item label="监察员名称" prop="username">
                    <el-input v-model="bmsGrowRecordDto.username" style="width: 50%" />
                </el-form-item>
                <el-form-item label="联系电话" prop="tel">
                    <el-input v-model="bmsGrowRecordDto.tel" style="width: 50%" />
                </el-form-item>
                <el-form-item label="记录时间" prop="recordDate">
                    <el-date-picker v-model="bmsGrowRecordDto.recordDate" type="date" @change="selectChange1"
                        value-format="yyyy-MM-dd" placeholder="选择日期">

                    </el-date-picker>
                </el-form-item>
       
        <el-form-item label="合同文件"  prop="fileDtoList">
          <el-upload class="upload-demo" ref="up" action="http://up.qiniup.com" :show-file-list="true" 
          :on-preview="handlePreview"
          :file-list="fileList"
            accept="" :on-progress="uploadVideoProcess"  :on-remove="handleRemovesp"
            list-type="picture" 
            :on-success="handleAvatarSuccesssp" :data="postData" :before-upload="beforeAvatarUploadsp"
            :disabled="loadProgress == 0 ? false : loadProgress == 101 ? false : true">
            <el-button type="primary" :disabled="loadProgress == 0 ? false : loadProgress == 101 ? false : true">点击上传
            </el-button>
           
          </el-upload>

        </el-form-item>
        
            </el-form>
            <el-row slot="footer" type="flex" justify="center">
                <el-col :span="6">
                    <el-button size="small" type="primary" @click="btnOK">
                        确定
                    </el-button>
                    <el-button size="small" @click="btnCancel">取消</el-button>
                </el-col>
            </el-row>
        </el-dialog>

        <!-- -------万恶分割线------- -->



        <!-- 主体内容 -->

        <div class="tab">
            <el-table :header-cell-style="{ background: '#F2F6FC', color: '#606266' }" :data="list" border
                style="width: 100%" ref="list" class="emp_table" v-if="list">
                <el-table-column align="center" prop="title" label="名称" :show-overflow-tooltip="true" />
                <el-table-column align="center" prop="username" label="监察员名称" :show-overflow-tooltip="true" />
                <el-table-column align="center" prop="tel" label="联系电话" :show-overflow-tooltip="true" />
                <el-table-column align="center" prop="recordDate" label="记录时间" :show-overflow-tooltip="true" />

                <el-table-column align="center" label="操作">
                    <template slot-scope="scope">

                        <el-button type="text" size="small" @click="updateManagerUser(scope.row)">
                            编辑
                        </el-button>

                        <el-button type="text" size="small" @click="delgrowRecord(scope.row)">
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <pagination v-show="total > 0" :total="total" :page.sync="getRecordListDto.pageNum"
                :limit.sync="getRecordListDto.pageSize" @pagination="growRecordList" />
        </div>
    </div>
</template>
    
<script>

import {
    getUploadToken,
    growRecordList,
    delgrowRecord,
    addgrowRecord,
    updategrowRecord,
} from "../../request/http";

export default {
    data() {
        return {
            loadProgress: 0,
            fileType:null,
            res: [],
            title: "",
            loading: true,//加载动画
            showDialog: false,
            list: "",
            total: 0,
            getRecordListDto: {
                pageNum: 1,
                pageSize: 10,
                companyId: null,
                contractId: null
            },
            fileList: [],
            fileList1: [],
            postData: {
        token: "", //上传凭证
        fid: "", //文件id
        fileDomain: "" //文件域名
      },
            dialogVisible: false, //显示添加或编辑框
            bmsGrowRecordDto: {
                contractId: null,
                fileDtoList: [],
                recordDate: "",
                username: "",
                tel: ""
            },

            rules: {

                fileDtoList: [
                    { required: true, message: "生长记录文件不能为空", trigger: "blur" },
                ],
                recordDate: [
                    { required: true, message: "生长记录时间不能为空", trigger: "blur" },
                ],
                username: [
                    { required: true, message: "监察员名称不能为空", trigger: "blur" },
                ],
                tel: [
                    { required: true, message: "联系电话不能为空", trigger: "blur" },
                    {
                        pattern:
                            /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/,
                        message: "请输入正确的手机号码",
                    },
                ],


            },
        };
    },

    created() {
        this.getRecordListDto.companyId = this.$route.query.companyId;
        this.getRecordListDto.contractId = this.$route.query.contractId;
        this.bmsGrowRecordDto.contractId = this.$route.query.contractId;
        this.growRecordList();
    },

    mounted() { },
    methods: {
   


        handlePreview(file) {
     console.log(file);
     let key;

file.key ? key = file.key.fid : key = file.response.key
       


        window.open('http://farmfile.uutele.com/' + key, '_blank')
       
      } ,
      
    
        selectChange1(value) {
            this.bmsGrowRecordDto.recordDate = value
            console.log(this.bmsGrowRecordDto.recordDate);
        },
   
   
    handleRemovesp(file) {
      console.log(file);
      let key;

      file.key ? key = file.key.fid : key = file.response.key

      let i = this.bmsGrowRecordDto.fileDtoList.findIndex((item) => item.fid === key)

      // let i = this.bmsGrowRecordDto.fileDtoList.indexOf(key);

      console.log(key, i);
      // console.log(i);
      //  this.bmsGrowRecordDto.files=  this.bmsGrowRecordDto.files.filter(item => item.fid!==key)
      this.bmsGrowRecordDto.fileDtoList.splice(i, 1);
      console.log(this.bmsGrowRecordDto.fileDtoList);

    },
    // 上传方法
    handleAvatarSuccesssp(response, file, fileList) {
      console.log('上传成功', response,file.raw.type)
      if(file.raw.type.includes('image')){
        this.bmsGrowRecordDto.fileDtoList.push({
        fid: response.key,
        fileType: 1
      });
      }
      else if (file.raw.type.includes('video')){
        this.bmsGrowRecordDto.fileDtoList.push({
        fid: response.key,
        fileType: 2
      });
      file.url="http://farmfile.uutele.com/" + response.key+'?vframe/jpg/offset/0'
      }
     
      this.loadProgress = this.loadProgress + 1
   
      console.log(this.bmsGrowRecordDto.fileDtoList);
    
    
      
    },
    async beforeAvatarUploadsp(file) {
      this.loadProgress = 0
      let { data } = await getUploadToken()
      if (data == '' || data == null) {
        this.message.error('上传文件时获取Token失败！')
        return
      }
      this.postData.token = data.data.token;
      this.postData.fid = data.data.fid;
      this.postData.fileDomain = data.data.fileDomain;
    },
    uploadVideoProcess(event, file, fileList) {
      this.loadProgress = parseInt(event.percent); // 动态获取文件上传进度
      console.log(this.loadProgress);
    },

      
        goback() {
            this.$router.go(-1)
        },

        //获取牧场列表
        async growRecordList() {
            let { data } = await growRecordList(this.getRecordListDto);
            let list = data.data;
            console.log(list);
            this.list = list.records;

            this.total = list.total;
            this.loading = false
        },

        // 打开添加的窗口
        addgrowRecord() {
            this.title = "添加生长记录";

            this.showDialog = true;
        },
        // 打开编辑类别的窗口
        updateManagerUser(val) {
            // 根据获取id获取详情
            this.title = "编辑生长记录";
            var images = val.fileDtoList
            
            console.log(val);

                this.bmsGrowRecordDto.id = val.id,
                this.bmsGrowRecordDto.files = val.files,
                this.bmsGrowRecordDto.contractId = val.contractId,
                this.bmsGrowRecordDto.recordDate = val.recordDate,
                this.bmsGrowRecordDto.username = val.username,
           
                this.bmsGrowRecordDto.fileDtoList=val.fileDtoList
                images,
                this.bmsGrowRecordDto.tel = val.tel;
            
                    for (let i = 0; i < images.length; i++) {
                        if(images[i].fileType==1){
                            this.fileList.push({
                    url: "http://farmfile.uutele.com/" + images[i].fid,
                    name:'图片',
                    key: images[i],
                });
                }    else if (images[i].fileType==2){
                    this.fileList.push({
                    url: "http://farmfile.uutele.com/" + images[i].fid+'?vframe/jpg/offset/0',
                    name:'视频',
                    key: images[i],
                });
                }
                 
              
                }
            
            
            


                this.showDialog = true;
        },
        // 确认
        async btnOK() {
            this.$refs.perForm.validate().then(async () => {
                if (this.bmsGrowRecordDto.id) {
                    let { data } = await updategrowRecord(this.bmsGrowRecordDto);
                    if (this.bmsGrowRecordDto.id && data.code == 0) {
                        this.$message.success("编辑成功");
                    } else {
                        console.log(data.code);
                        this.$message({
                            type: "error",
                            message: data.code,
                        });
                    }
                    this.growRecordList();

                    this.showDialog = false;
                } else {
                    let { data } = await addgrowRecord(this.bmsGrowRecordDto);
                    console.log(data);
                    if (data.code == 0) {
                        this.$message.success("新增成功");
                    } else {
                        console.log(data.msg);
                        this.$message({
                            type: "error",
                            message: data.msg,
                        });
                    }
                    this.growRecordList();

                    this.showDialog = false;
                }
            });
        },
        //弹窗取消函数
        btnCancel() {
            this.bmsGrowRecordDto = { contractId: this.$route.query.contractId,
                fileDtoList: [],
                recordDate: "",
                username: "",
                tel: ""};
                this.fileList=[]
                this.fileList1=[]
                this.loadProgress=0,
                this.fileType=0
            this.$refs.perForm.resetFields();
            this.showDialog = false;
        },

        async delgrowRecord(row) {
            try {
                await this.$confirm("确定要删除该生长记录吗");
                // console.log(row);
               
                let id = row.id;
                let delRecordDto={
                    contractId: this.$route.query.contractId,
                    recordId:id
                }
                console.log(delRecordDto);
                let { data } = await delgrowRecord(delRecordDto);

                if (data.code == 0) {
                    this.$message({
                        type: "success",
                        message: "删除成功",
                    });
                } else {
                    this.$message({
                        type: "error",
                        message: data.msg,
                    });
                }
                const totalPage = Math.ceil(
                    (this.total - 1) / this.getRecordListDto.pageSize
                );
                this.getRecordListDto.pageNum =
                    this.getRecordListDto.pageNum > totalPage ? totalPage : this.getRecordListDto.pageNum;
                this.getRecordListDto.pageNum =
                    this.getRecordListDto.pageNum < 1 ? 1 : this.getRecordListDto.pageNum;

                this.growRecordList();
            } catch (error) {
                console.log(error);
            }
        },
    },
};
</script>
    
<style scoped>

</style>
    